<template>
  <DefaultTemplate
    v-loading="loadingExport || loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="set-breadcrumb"
      >
        <el-breadcrumb-item :to="{ path: '/user' }"
          >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
        >
        <el-breadcrumb-item>
          <!-- จัดการข้อมูลผู้ประกอบการ  -->
          {{ getCriterionName }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="box-name-page">
        <p class="title">
          <!-- จัดการข้อมูลผู้ประกอบการ  -->
          {{ getCriterionName }}
        </p>
        <div class="is-flex ai-center" v-if="permissionExportMore()">
          <el-dropdown
            trigger="click"
            v-if="user.role.roleLevel != 60 && user.role.roleLevel != 70"
          >
            <el-button type="primary" class="export-user">
              <div class="group">
                <span> Export </span>
                <i class="fas fa-chevron-down pd-l-7 mg-0"></i>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="export-user">
              <el-dropdown-item>
                <span @click="exportETC">Export แบบหน้าจัดการ</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="exportAll">Export ทั้งหมด</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="getDraftUser">จัดการตาราง Export</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            type="primary"
            class="export-user"
            v-else-if="user.role.roleLevel == 60 || user.role.roleLevel == 70"
            @click="getDraftUser"
          >
            <div class="group">
              <span>Export</span>
            </div>
          </el-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="ปีที่สนใจผลิตภัณฑ์">
              <el-select
                id="selectInterestYear"
                v-model="form.interestYear"
                placeholder="เลือกปีที่สนใจผลิตภัณฑ์"
                @change="searchCompany"
                clearable
              >
                <el-option
                  v-for="item in optionsYearOfInterest"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="สถานะลูกหนี้">
              <el-select
                id="selectDebtorStatus"
                v-model="form.debtorStatus"
                placeholder="เลือกสถานะลูกหนี้"
                @change="searchCompany"
                clearable
                default-first-option
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsDebtor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4" v-if="user.role.roleLevel != 60">
            <el-form-item label="เกรดสินเชื่อ">
              <el-select
                id="selectGrade"
                multiple
                filterable
                clearable
                default-first-option
                v-model="form.grade"
                placeholder="เลือกเกรดสินเชื่อ"
                popper-class="custom-select"
                @change="searchCompany"
              >
                <el-option
                  v-for="item in optionsGrade"
                  :key="item._id"
                  :label="item.gradeName"
                  :value="item._id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.gradeName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="ผลิตภัณฑ์ที่นำเสนอ">
              <el-select
                id="selectOffer"
                v-model="form.offer"
                placeholder="เลือกผลิตภัณฑ์ที่นำเสนอ"
                @change="searchCompany"
                clearable
                default-first-option
                multiple
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsInterestedServices"
                  :disabled="disbledIfOffer(item.value)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    <span>{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="ผลิตภัณฑ์ที่สนใจ">
              <el-select
                id="selectInterest"
                v-model="form.interest"
                placeholder="เลือกผลิตภัณฑ์ที่สนใจ"
                @change="searchCompany"
                clearable
                default-first-option
                multiple
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsInterestedServices2"
                  :disabled="disbledIfInterest(item.value)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    <span>{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="สถานะ Lead สินเชื่อ">
              <el-select
                id="selectLeadStatus"
                v-model="form.leadStatus"
                placeholder="เลือกสถานะ Lead สินเชื่อ"
                @change="searchCompany"
                clearable
                default-first-option
                popper-class="custom-select"
                multiple
              >
                <el-option
                  v-for="item in optionsCRM"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    <span>{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="สถานะของการเข้าพบลูกค้า">
              <el-select
                id="selectMeetStatus"
                v-model="form.meetStatus"
                placeholder="เลือกสถานะของการเข้าพบลูกค้า"
                @change="searchCompany"
                clearable
                default-first-option
                popper-class="custom-select"
                multiple
              >
                <el-option
                  v-for="item in optionsMeetStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    <span>{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4" v-if="permissionCredit">
            <el-form-item label="สถานะสินเชื่อ">
              <el-select
                id="selectStatusCredit"
                multiple
                filterable
                clearable
                default-first-option
                v-model="form.statusTextCredit"
                placeholder="เลือกสถานะสินเชื่อ"
                popper-class="custom-select"
                @change="searchCompany"
              >
                <el-option
                  v-for="item in optionsStatusCredit"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    <span
                      v-if="item.value == 'ไม่อนุมัติ'"
                      style="color: #ff646a"
                    >
                      {{ item.label }}
                    </span>
                    <span v-if="item.value == 'อนุมัติ'" style="color: #09af24">
                      {{ item.label }}
                    </span>
                    <span
                      v-if="item.value == 'อนุมัติตามเสนอ'"
                      style="color: #09af24"
                    >
                      {{ item.label }}
                    </span>
                    <span
                      v-if="item.value == 'อนุมัติมีเงื่อนไข'"
                      style="color: #38d85b"
                    >
                      {{ item.label }}
                    </span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4" v-if="permissionInsurance">
            <el-form-item label="สถานะ Pipeline ประกัน">
              <el-select
                id="selectStatusPipeline"
                multiple
                filterable
                clearable
                default-first-option
                v-model="form.statusTextInsurance"
                placeholder="เลือกสถานะ Pipeline ประกัน"
                popper-class="custom-select"
                @change="searchCompany"
              >
                <el-option
                  v-for="item in optionsStatusPipeline"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.label }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4" v-if="permissionInsurance">
            <el-form-item label="ผลการติดต่อลูกค้าประกัน">
              <el-select
                id="selectStatusPipeline"
                multiple
                filterable
                clearable
                default-first-option
                v-model="form.contactStatus"
                placeholder="เลือกผลการติดต่อลูกค้าประกัน"
                popper-class="custom-select"
                @change="searchCompany"
              >
                <el-option
                  v-for="item in optionsContactStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.label }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="ที่มา">
              <el-select
                id="selectBytype"
                v-model="form.dataType"
                placeholder="เลือกที่มา"
                @change="searchCompany"
                clearable
                default-first-option
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsDataType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="4">
            <el-form-item label="ค้นหา">
              <el-input
                id="searchUser"
                placeholder="ค้นหาชื่อ/นามสกุล/บริษัท/เลขนิติบุคคล"
                suffix-icon="el-icon-search"
                v-model="form.search"
                class="search"
                clearable
                @input="searchCompany"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div
      class="box-card mg-t-5 animate__animated animate__fadeIn animate__slow"
    >
      <el-row :gutter="20">
        <TableUser
          :user="user"
          :tableData="tableData"
          @moreCredit="getStatusById"
          @moreInsurance="getInsuranceById"
          @downloadFinancial="downloadFinancial"
          :loading="loading"
          :checkPersonal="permissionPersonal()"
          :checkCompanyMore="permissionCompanyMore()"
          :checkCompany="permissionCompany()"
          :checkDownload="permissionDownload()"
          :permissionCredit="permissionCredit"
          :permissionInsurance="permissionInsurance"
        />
        <el-col :span="24">
          <div class="text-center mg-t-5">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="pageNow"
              :page-sizes="[15, 30, 50, 100, 500]"
              :page-size="15"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalNow"
            >
            </el-pagination>
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="totalNow"
              :current-page="pageNow"
              :page-size="limit"
              @current-change="changePage"
            ></el-pagination> -->
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      class="dialog-custom financial"
      title="Financial Projection"
      :visible.sync="dialogDownload"
      width="35%"
      top="10vh"
      center
      :before-close="closeDialogDownload"
    >
      <div class="mg-t-4 pd-x-4">
        <p>ขั้นตอนการใช้ข้อมูล Financial Projection</p>
        <p class="mg-t-5">
          1. ดาวน์โหลด Template และ คู่มือการใช้งานการทำ Financial Projection
        </p>
        <el-button
          type="primary"
          class="font-24 mg-y-7"
          @click="submitDownloadTemplate"
          ><i class="fas fa-download"></i> Download zip</el-button
        >
        <p>2. ดาวน์โหลดงบการเงินประกอบการทำ Financial Projection</p>
        <!-- :disabled="!checked" -->
        <el-button
          type="primary"
          class="font-24 mg-y-7"
          @click="submitDownloadFinancial"
          ><i class="fas fa-download"></i> Download</el-button
        >
        <p>3. จากนั้นทำตามคู่มือในไฟล์ cfoe_fp_template.zip</p>
      </div>
      <hr />
      <div class="pd-x-4">
        <div class="is-flex ai-center">
          <el-checkbox
            @change="connectCustomer"
            v-model="checked"
            :disabled="connectCustomerNow == true ? true : false"
          ></el-checkbox>
          <p class="mg-l-6 font-24 font-weight-500">
            คลิกหากคุณติดต่อผู้ประกอบการเรียบร้อยแล้ว
          </p>
        </div>
        <span class="color-9c"
          >คลิกหากคุณนำไฟล์ Financial Projection
          นำไปประกอบกับการติดต่อผู้ประกอบการเรียบร้อยแล้ว</span
        >
      </div>

      <!-- <div class="text-center">
        <el-button
          type="primary"
          class="font-24"
          :disabled="!checked"
          @click="submitDownloadFinancial"
          >Download</el-button
        >
      </div> -->
    </el-dialog>
    <el-dialog
      id="dialog-export-user"
      title="จัดการตาราง Export CSV"
      v-if="dialogManageExport"
      :visible.sync="dialogManageExport"
      top="5vh"
      center
    >
      <div class="box-list-export">
        <el-row :gutter="30">
          <el-col :span="24" v-if="loadGetDraft">
            <ExportCSV
              :userInfo="exportCSV"
              @downloadCSV="downloadCSV"
              :gradeAll="optionsGradeAll"
              :permissionCredit="permissionCredit"
              :permissionInsurance="permissionInsurance"
            />
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-custom user-list"
      title="สถานะสินเชื่อ"
      v-if="dialogStatusCredit"
      :visible.sync="dialogStatusCredit"
      width="70%"
      top="10vh"
      center
    >
      <div v-loading="loadingStatusCredit">
        <div v-if="!loadingStatusCredit">
          <div class="scroll-table-delete">
            <el-table
              class="table-normal"
              :data="fetchStatusCredit"
              style="width: 100%"
            >
              <el-table-column label="วันที่สนใจ" width="140">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.createdAt | formatDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="ชื่อสินเชื่อ" min-width="150px">
                <template slot-scope="scope">
                  <p class="text-left mg-0">{{ scope.row.productName }}</p>
                </template>
              </el-table-column>
              <el-table-column label="สถานะ" prop="status" width="150px">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.status == 'ไม่อนุมัติ'"
                    style="color: #ff646a"
                  >
                    ไม่อนุมัติ
                  </span>
                  <span
                    v-else-if="
                      scope.row.status == 'อนุมัติ' ||
                      scope.row.status == 'อนุมัติตามเสนอ'
                    "
                    style="color: #09af24"
                  >
                    อนุมัติ
                  </span>
                  <!-- <span
                    v-else-if="scope.row.status == 'อนุมัติตามเสนอ'"
                    style="color: #09af24"
                  >
                    อนุมัติตามเสนอ
                  </span> -->
                  <span
                    v-else-if="
                      scope.row.status == 'อนุมัติแบบมีเงื่อนไข' ||
                      scope.row.status == 'อนุมัติมีเงื่อนไข'
                    "
                    style="color: #38d85b"
                  >
                    อนุมัติมีเงื่อนไข
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="เหตุผล" min-width="150px">
                <template slot-scope="scope">
                  <div v-if="scope.row.reason">
                    <p
                      class="text-left mg-0 normal"
                      :class="scope.row.statusShowDetail ? 'show' : ''"
                    >
                      {{ scope.row.reason }}
                    </p>
                    <p
                      class="text-center mg-0 color-main"
                      v-if="scope.row.reason.length > 53"
                    >
                      <span
                        class="text-decoration-underline cursor-pointer"
                        @click="scope.row.statusShowDetail = true"
                        v-if="scope.row.statusShowDetail == false"
                        >เพิ่มเติม <i class="fas fa-chevron-down"></i
                      ></span>
                      <span
                        class="text-decoration-underline cursor-pointer"
                        @click="scope.row.statusShowDetail = false"
                        v-else
                        >แสดงน้อยลง <i class="fas fa-chevron-up"></i
                      ></span>
                    </p>
                  </div>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="โครงการที่เข้าร่วม" min-width="150px">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.projectTerak != undefined &&
                      scope.row.projectTerak != null
                    "
                    >{{ scope.row.projectTerak.projectName }}</span
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <p class="mg-0 text-center mg-t-4">
            <span
              class="font-16 color-c4 text-decoration-underline cursor-pointer"
              @click="dialogStatusCredit = false"
              >ย้อนกลับ</span
            >
          </p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-custom user-list"
      title="สถานะประกันการส่งออก"
      v-if="dialogStatusInsurance"
      :visible.sync="dialogStatusInsurance"
      width="70%"
      top="10vh"
      center
    >
      <div v-loading="loadingStatusInsurance">
        <div v-if="!loadingStatusInsurance">
          <div class="scroll-table-delete">
            <el-table
              class="table-normal"
              :data="fetchStatusInsurance"
              style="width: 100%"
            >
              <el-table-column label="วันที่สนใจ" width="140">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.createdAt | formatDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="ชื่อประกันการส่งออก" min-width="150px">
                <template slot-scope="scope">
                  <p class="text-left mg-0">{{ scope.row.productName }}</p>
                </template>
              </el-table-column>
              <el-table-column
                label="สถานะ Pipeline"
                prop="pipelineStatus"
                width="150px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.pipelineStatus">
                    {{ scope.row.pipelineStatus }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="ผลการติดต่อลูกค้า" min-width="150px">
                <template slot-scope="scope">
                  <span v-if="scope.row.contactStatus">
                    {{ scope.row.contactStatus }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="โครงการที่เข้าร่วม" min-width="150px">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.projectTerak != undefined &&
                      scope.row.projectTerak != null
                    "
                    >{{ scope.row.projectTerak.projectName }}</span
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <p class="mg-0 text-center mg-t-4">
            <span
              class="font-16 color-c4 text-decoration-underline cursor-pointer"
              @click="dialogStatusInsurance = false"
              >ย้อนกลับ</span
            >
          </p>
        </div>
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import ExportCSV from "@/components/user/ExportCSV";
import TableUser from "@/components/user/TableUser";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    DefaultTemplate,
    ExportCSV,
    TableUser,
  },
  mounted() {
    this.$store.commit(
      "SET_PAGE_ACTIVE",
      "user/detail/" + this.$route.params.id
    );
    // this.getAllGrade();
    // this.getDraftUser();
    this.getGradeByid();
    this.getUserCompanyById();
    this.permissionPersonal();
    this.permissionCompany();
    this.permissionCompanyMore();
    this.permissionDownload();
  },
  data() {
    return {
      permissionMoreInterested: null,
      permissionCredit: null,
      permissionInsurance: null,
      juristicIdNow: "",
      loadingFinancial: false,
      optionsMeetStatus: [
        {
          value: "อยู่ระหว่างเจรจา",
          label: "อยู่ระหว่างเจรจา",
        },
        {
          value: "รอเยี่ยมชมกิจการ",
          label: "รอเยี่ยมชมกิจการ",
        },
        {
          value: "พบลูกค้าและเยี่ยมชมกิจการ",
          label: "พบลูกค้าและเยี่ยมชมกิจการ",
        },
        {
          value: "รอเอกสารเพิ่มเติม",
          label: "รอเอกสารเพิ่มเติม",
        },
      ],
      optionsGradeAll: [],
      loadingStatusInsurance: true,
      dialogStatusInsurance: false,
      fetchStatusInsurance: [],
      loadingStatusCredit: true,
      fetchStatusCredit: [],
      dialogStatusCredit: false,
      loadGetDraft: false,
      exportCSV: {
        date: "",
        dataType: [],
        grade: [],
        statusLead: [],
        allTime: false,
        personalAll: false,
        companyAll: false,
        creditAll: false,
        insuranceAll: false,
        projectAll: false,
        personal: {
          email: false,
          // registerDate: false,
          title: false,
          name: false,
          surname: false,
          // gender: false,
          // age: false,
          // education: false,
          mobilephone: false,
        },
        company: {
          companyName: false,
          juristicId: false,
          establishmentYear: false,
          registerCapital: false,
          businessKey: false,
          directorType: false,
          companyMail: false,
          produceOrSoldExport: false,
          exportPlan: false,
          businessType: false,
          industryType: false,
          cateIndustryType: false,
          memberOf: false,
          // employment: false,
          latestAnnualRevenues: false,
          operatorType: false,
          exportExperience: false,
          exportRatio: false,
          orderConsistency: false,
          exportFrequency: false,
          shipment: false,
          paymentTerm: false,
          exportValue: false,
          exportCountry: false,
          expectedPercentageEtc: false,
        },
        credit: {
          statusApprove: false,
          reason: false,
        },
        exportInsurance: {
          pipelineStatus: false,
          contactStatus: false,
        },
        project: {
          interestProject: false,
          // notInterestProject: false,
          // projectInfo: false,
        },
      },
      checked: false,
      downloadFinanNow: false,
      connectCustomerNow: false,
      dialogManageExport: false,
      dialogDownload: false,
      form: {
        search: "",
        interestYear: "",
        interest: [],
        leadStatus: [],
        grade: [],
        debtorStatus: "",
        statusTextCredit: [],
        statusTextInsurance: [],
        contactStatus: [],
        meetStatus: [],
        offer: [],
        dataType: "",
      },
      optionsDataType: [
        {
          value: "TERAK",
          label: "TERAK",
        },
        {
          value: "Channel Integration",
          label: "Channel Integration",
        },
      ],
      optionsStatusCredit: [
        {
          value: "ไม่อนุมัติ",
          label: "ไม่อนุมัติ",
        },
        {
          value: "อนุมัติ",
          label: "อนุมัติ",
        },
        // {
        //   value: "อนุมัติตามเสนอ",
        //   label: "อนุมัติตามเสนอ",
        // },
        {
          value: "อนุมัติมีเงื่อนไข",
          label: "อนุมัติมีเงื่อนไข",
        },
      ],
      optionsStatusPipeline: [
        {
          value: "อยู่ระหว่างพิจารณา",
          label: "อยู่ระหว่างพิจารณา",
        },
        {
          value: "รอข้อมูลจากลูกค้า",
          label: "รอข้อมูลจากลูกค้า",
        },
        {
          value: "ได้รับเอกสาร/ข้อมูลเบื้องต้น",
          label: "ได้รับเอกสาร / ข้อมูลเบื้องต้น",
        },
        {
          value: "อนุมัติกรมธรรม์",
          label: "อนุมัติกรมธรรม์",
        },
        {
          value: "ลงนามกรมธรรม์",
          label: "ลงนามกรมธรรม์",
        },
      ],
      optionsContactStatus: [
        {
          value: "1",
          label: "ติดต่อไม่ได้",
        },
        {
          value: "2",
          label: "ไม่สนใจบริการ",
        },
        {
          value: "3",
          label: "ขาดการติดต่อเกิน 3 เดือน",
        },
      ],
      // optionsYearOfInterest: this.$store.state.userOption.yearOfInterest,
      optionsCRM: [
        { value: 1, label: "New" },
        { value: 2, label: "Pending" },
        { value: 3, label: "Active" },
        { value: 4, label: "Submitted" },
        { value: 0, label: "Close" },
        { value: 5, label: "ไม่มีสถานะ Lead" },
      ],
      // optionsCRM: this.$store.state.userOption.statusCRM,
      optionsInterestedServices: [
        {
          value: "credit",
          label: "สินเชื่อ",
        },
        {
          value: "insurance",
          label: "ประกันการส่งออก",
        },
        {
          value: "project",
          label: "โครงการอบรม",
        },
        {
          value: "notOffer",
          label: "ไม่นำเสนอผลิตภัณฑ์",
        },
      ],
      optionsInterestedServices2: [
        {
          value: "credit",
          label: "สินเชื่อ",
        },
        {
          value: "insurance",
          label: "ประกันการส่งออก",
        },
        {
          value: "project",
          label: "โครงการอบรม",
        },
        {
          value: "notInterest",
          label: "ไม่สนใจผลิตภัณฑ์",
        },
      ],
      tableData: [],
      pageNow: 1,
      totalNow: 10,
      limit: 15,
      loading: false,
      loadingData: true,
      debounce: null,
      urlBase: process.env.VUE_APP_BASE_URL,
      loadingExport: false,
      getCriterionName: "",
      optionsGrade: [],
      optionsDebtor: [
        { value: "Yes", label: "NPLs" },
        { value: "No", label: "ไม่เป็น NPLs" },
      ],
      userId: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
    }),
    optionsYearOfInterest() {
      let year = new Date().getFullYear() + 543;
      let yearStart = 2564;
      let returnYear = [];
      for (let i = 0; i < year + 1 - yearStart; i++) {
        let obj = {
          value: (year - i).toString(),
          label: (year - i).toString(),
        };
        returnYear.push(obj);
      }

      let month = Number(moment().format("M"));
      if (month == 12) {
        returnYear.unshift({
          value: (year + 1).toString(),
          label: (year + 1).toString(),
        });
      }
      return returnYear;
    },
  },
  methods: {
    closeDialogDownload() {
      this.dialogDownload = false;
      this.checked = false;
      this.downloadFinanNow = false;
      this.connectCustomerNow = false;
    },
    submitDownloadTemplate() {
      this.loading = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/download/financial/projection`)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message:
                "Download Template คู่มือการใช้งาน Financial Projection ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
            var file_path =
              this.urlBase + "financial/projection/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    connectCustomer() {
      let obj = {
        userId: this.userId,
        juristicId: this.juristicIdNow,
        status: true,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/financial/contract`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "ติดต่อผู้ประกอบการเรียบร้อย",
              type: "success",
              duration: 4000,
            });
            this.connectCustomerNow = true;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          // this.checked = false;
          // this.juristicIdNow = "";
          // this.loading = false;
          // this.loadingFinancial = false;
          // this.loadingExport = false;
        });
    },
    submitDownloadFinancial() {
      this.dialogDownload = false;

      this.loading = true;
      this.loadingExport = true;
      let obj = {
        userId: this.userId,
        juristicId: this.juristicIdNow,
        status: true,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/export/financial-projection`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "Download Financial Projection ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loadingExport = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.checked = false;
          this.juristicIdNow = "";
          this.loading = false;
          this.loadingFinancial = false;
          this.loadingExport = false;
        });
    },
    // getAllGrade() {
    //   HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
    //   HTTP.get(`criterion/grade/${this.$route.params.id}`)
    //     .then((res) => {
    //       if (res.data.success) {
    //         this.optionsGradeAll = res.data.obj;
    //         this.optionsGradeAll.unshift({
    //           _id: Number("999"),
    //           gradeName: "ทั้งหมด",
    //         });
    //       }
    //     })
    //     .catch((e) => {
    //       if (e.response.data.status == 401) {
    //         this.checkAuth();
    //       } else {
    //         this.alertCatchError(e);
    //       }
    //     });
    // },
    permissionPersonal() {
      let ifPermission = null;
      ifPermission = this.permissionNow.some(
        (e) => e.pemNum == 9 && e.status != 3
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    permissionCompany() {
      let ifPermission = null;
      ifPermission = this.permissionNow.some(
        (e) => e.pemNum == 10 && e.status != 3
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    permissionCompanyMore() {
      let ifPermission = null;
      ifPermission = this.permissionNow.some(
        (e) => e.pemNum == 30 && e.status != 3
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    permissionExportMore() {
      let ifPermission = null;
      ifPermission = this.permissionNow.some(
        (e) => e.pemNum == 12 && e.status != 3
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    permissionDownload() {
      let ifPermission = null;
      ifPermission = this.permissionNow.some(
        (e) => e.pemNum == 11 && e.status != 3
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    async getDraftUser() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        empId: this.user.id,
        criterionId: this.$route.params.id,
      };
      HTTP.post(`draft`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.dialogManageExport = true;

            let setData = res.data.obj;
            if (setData != null) {
              var setObjDraft = {
                grade: setData.grade,
                date:
                  setData.allTime == false
                    ? [setData.exportStart, setData.exportEnd]
                    : "",
                statusLead:
                  setData.statusLead.length == 0 ? [999] : setData.statusLead,
                dataType:
                  setData.dataType.length == 2 ? [999] : setData.dataType,
                allTime: setData.allTime,
                personalAll: setData.personalAll,
                companyAll: setData.companyAll,
                creditAll: setData.creditAll,
                insuranceAll: setData.insuranceAll,
                projectAll: setData.projectAll,
                personal: {
                  email: setData.email,
                  // registerDate: setData.registerDate,
                  title: setData.title,
                  name: setData.name,
                  surname: setData.surname,
                  // gender: setData.gender,
                  // age: setData.age,
                  // education: setData.education,
                  mobilephone: setData.mobilephone,
                },
                company: {
                  companyName: setData.companyName,
                  juristicId: setData.juristicId,
                  establishmentYear: setData.establishmentYear,
                  businessKey: setData.businessKey,
                  directorType: setData.directorType,
                  companyMail: setData.companyMail,
                  produceOrSoldExport: setData.produceOrSoldExport,
                  exportPlan: setData.exportPlan,
                  registerCapital: setData.registerCapital,
                  businessType: setData.businessType,
                  industryType: setData.industryType,
                  cateIndustryType: setData.cateIndustryType,
                  memberOf: setData.memberOf,
                  // employment: setData.employment,
                  latestAnnualRevenues: setData.latestAnnualRevenues,
                  operatorType: setData.operatorType,
                  exportExperience: setData.exportExperience,
                  exportRatio: setData.exportRatio,
                  orderConsistency: setData.orderConsistency,
                  exportFrequency: setData.exportFrequency,
                  shipment: setData.shipment,
                  paymentTerm: setData.paymentTerm,
                  exportValue: setData.exportValue,
                  exportCountry: setData.exportCountry,
                  expectedPercentageEtc: setData.expectedPercentageEtc,
                },
                credit: {
                  statusApprove: setData.statusApprove,
                  reason: setData.reason,
                },
                exportInsurance: {
                  pipelineStatus: setData.pipelineStatus,
                  contactStatus: setData.contactStatus,
                },
                project: {
                  interestProject: setData.interestProject,
                  // notInterestProject: setData.notInterestProject,
                  // projectInfo: setData.projectInformation,
                },
              };
              this.exportCSV = setObjDraft;
            }
            this.loadGetDraft = true;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    downloadCSV(formSelect) {
      this.dialogManageExport = false;
      this.loadingExport = true;
      let grade = formSelect.grade.findIndex((a) => a === 999);
      if (grade > -1) {
        formSelect.grade.splice(grade, 1);
      }

      let forGradeString = [];
      let forLeadString = [];
      let forDataType = [];
      formSelect.grade.forEach((row) => {
        forGradeString.push(row.toString());
      });
      formSelect.statusLead.forEach((row) => {
        forLeadString.push(row.toString());
      });
      formSelect.dataType.forEach((row) => {
        if (row != "999") {
          forDataType.push(row.toString());
        }
      });

      let obj = {
        id: this.$route.params.id,
        grade: forGradeString,
        exportStart:
          formSelect.allTime == true
            ? ""
            : moment(formSelect.date[0]).format("YYYY-MM-DD") + " 00:00:00",
        exportEnd:
          formSelect.allTime == true
            ? ""
            : moment(formSelect.date[1]).format("YYYY-MM-DD") + " 23:59:59",
        allTime: formSelect.allTime,
        statusLead: formSelect.statusLead[0] == 999 ? [] : forLeadString,
        dataType: forDataType,
        personalAll: formSelect.personalAll,
        // registerDate: formSelect.personal.registerDate,
        title:
          formSelect.personal.title == null ? false : formSelect.personal.title,
        name: formSelect.personal.name,
        surname: formSelect.personal.surname,
        // gender: formSelect.personal.gender,
        // age: formSelect.personal.age,
        // education: formSelect.personal.education,
        email: formSelect.personal.email,
        mobilephone: formSelect.personal.mobilephone,
        companyAll: formSelect.companyAll,
        companyName: formSelect.company.companyName,
        juristicId: formSelect.company.juristicId,
        establishmentYear: formSelect.company.establishmentYear,
        registerCapital: formSelect.company.registerCapital,
        businessKey: formSelect.company.businessKey,
        directorType: formSelect.company.directorType,
        companyMail: formSelect.company.companyMail,
        produceOrSoldExport: formSelect.company.produceOrSoldExport,
        exportPlan: formSelect.company.exportPlan,
        businessType: formSelect.company.businessType,
        industryType: formSelect.company.industryType,
        cateIndustryType: formSelect.company.cateIndustryType,
        memberOf: formSelect.company.memberOf,
        // emploment: formSelect.company.employment,
        latestAnnualRevenues: formSelect.company.latestAnnualRevenues,
        operatorType: formSelect.company.operatorType,
        exportExperience: formSelect.company.exportExperience,
        exportRatio: formSelect.company.exportRatio,
        orderConsistency: formSelect.company.orderConsistency,
        exportFrequency: formSelect.company.exportFrequency,
        shipment: formSelect.company.shipment,
        paymentTerm: formSelect.company.paymentTerm,
        exportValue: formSelect.company.exportValue,
        exportCountry: formSelect.company.exportCountry,
        expectedPercentageEtc: formSelect.company.expectedPercentageEtc,

        creditAll: this.permissionCredit ? formSelect.creditAll : false,
        statusApprove: this.permissionCredit
          ? formSelect.credit.statusApprove
          : false,
        reason: this.permissionCredit ? formSelect.credit.reason : false,

        insuranceAll: this.permissionInsurance
          ? formSelect.insuranceAll
          : false,
        pipelineStatus: this.permissionInsurance
          ? formSelect.exportInsurance.pipelineStatus
          : false,
        contactStatus: this.permissionInsurance
          ? formSelect.exportInsurance.contactStatus
          : false,

        // projectAll: formSelect.projectAll,
        projectAll: false,
        interestProject: formSelect.project.interestProject,
        interestProject: false,
        notInterestProject: false,
        empId: this.user.id,
        // projectInfo: formSelect.project.projectInfo,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/export/custom`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.loadingExport = false;
            this.dialogManageExport = false;

            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
              HTTP.delete(`file/${res.data.obj.filename}`);
            }, 1000);
            // this.getDraftUser();
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          this.loadingExport = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadFinancial(
      juristicIdNow,
      id,
      statusFinancial,
      showContactFinancial
    ) {
      this.juristicIdNow = juristicIdNow;
      this.userId = id;
      this.dialogDownload = true;
      this.downloadFinanNow = statusFinancial;
      this.connectCustomerNow = showContactFinancial;
      if (this.connectCustomerNow == true) {
        this.checked = true;
      }

      // HTTP.get(`contactFinancialProjection/${this.userId}`) // /user
      //   .then((res) => {
      //     if (res.data.success) {
      //       // if (res.data.obj != null) {
      //       //   // this.downloadFinanNow = res.data.obj.contactFinancialProjection;
      //       //   this.downloadFinanNow = statusFinancial;
      //       //   if (this.downloadFinanNow == true) {
      //       //     this.checked = true;
      //       //   }
      //       // }
      //     }
      //   })
      //   .catch((e) => {
      //     if (e.response.data.status == 401) {
      //       this.checkAuth();
      //     } else {
      //       this.alertCatchError(e);
      //     }
      //   });
    },
    getUserCompanyById() {
      let arrayApproved = [];
      if (this.form.statusTextCredit.length > 0) {
        // if (typeof findApproved != "undefined" ||typeof findIfApproved != "undefined"  ) {
        let setApprovedNew = [];
        this.form.statusTextCredit.forEach((row) => {
          setApprovedNew.push(row);
          if (row == "อนุมัติ") {
            setApprovedNew.push("อนุมัติตามเสนอ");
          }
          // if (row == "อนุมัติแบบมีเงื่อนไข") {
          //   setApprovedNew.push("อนุมัติมีเงื่อนไข");
          // }
        });
        arrayApproved = setApprovedNew;
        // }

        // else if (
        //   typeof findApproved != "undefined" &&
        //   typeof find1 == "undefined"
        // ) {
        //   let setApprovedNew = [];
        //   this.form.statusTextCredit.forEach((row) => {
        //     if (row != 9999) {
        //       setApprovedNew.push(row);
        //     } else {
        //       setApprovedNew.push(1);
        //     }
        //   });
        //   setApproved = setApprovedNew;
        // }
      }
      let forGrade = [];
      let forLeadStatus = [];

      this.form.grade.forEach((row) => {
        forGrade.push(row.toString());
      });
      this.form.leadStatus.forEach((row) => {
        forLeadStatus.push(row.toString());
      });

      let obj = {
        _id: this.$route.params.id,
        page: this.pageNow.toString(),
        limit: this.limit.toString(),
        key: this.form.search.toString(),
        interestYear:
          this.form.interestYear == "all"
            ? "-"
            : this.form.interestYear.toString(),
        interest: this.form.interest,
        leadStatus: forLeadStatus,
        statusTextCredit: arrayApproved,
        statusTextInsurance: this.form.statusTextInsurance,
        contactStatus: this.form.contactStatus,
        grade: forGrade,
        offer: this.form.offer,
        debtorStatus: this.form.debtorStatus,
        meetStatus: this.form.meetStatus,
        dataType: this.form.dataType,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/criterion-detail`, obj) // /user
        .then(async (res) => {
          if (res.data.success) {
            this.getCriterionName = res.data.criterionName;
            const { totalPage, data } = res.data.obj;
            this.tableData = res.data.obj.data;
            this.totalNow = res.data.obj.totalPage;
            this.loading = false;
            this.loadingData = false;
            // this.permissionCredit = this.checkPermission(16);
            // this.permissionInsurance = this.checkPermission(17);
            // แสดงทั้งหมดไม่ว่า role จะเป็นสินเชื่อ / ประกัน
            this.permissionCredit = true;
            this.permissionInsurance = true;
            this.permissionMoreInterested = this.checkPermission(30);
            this.optionsInterestedServices.forEach((row, index) => {
              if (row.value == "credit" && this.user.role.roleLevel == 60) {
                this.optionsInterestedServices.splice(index, 1);
              }
              if (row.value == "insurance" && this.user.role.roleLevel == 70) {
                this.optionsInterestedServices.splice(index, 1);
              }
            });
            this.optionsInterestedServices2.forEach((row, index) => {
              if (row.value == "credit" && this.user.role.roleLevel == 60) {
                this.optionsInterestedServices2.splice(index, 1);
              }
              if (row.value == "insurance" && this.user.role.roleLevel == 70) {
                this.optionsInterestedServices2.splice(index, 1);
              }
            });
            // if(this.permissionMoreInterested == false){
            //   this.optionsInterestedServices = []
            // }
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.tableData = [];
              this.totalNow = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingData = false;
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.pageNow = page;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getUserCompanyById();
      }, 600);
      // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    searchCompany() {
      this.pageNow = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getUserCompanyById();
      }, 600);
    },
    getGradeByid() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/export/grade/${this.$route.params.id}`) // /user
        .then((res) => {
          if (res.data.success) {
            this.optionsGrade = JSON.parse(JSON.stringify(res.data.obj));
            this.optionsGradeAll = res.data.obj;
            this.optionsGradeAll.unshift({
              _id: Number("999"),
              gradeName: "ทั้งหมด",
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    async getStatusById(juristicId, companyId, branch) {
      try {
        this.loadingStatusCredit = true;
        this.dialogStatusCredit = true;

        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

        // check brach
        var res = null;
        if (branch == "TERAK") {
          let obj = {
            criterionId: this.$route.params.id,
            juristicId: juristicId,
            companyId: companyId,
          };
          res = await this.$store.dispatch(
            "user/fetchInterestCreditDetails",
            obj
          );
        } else if (branch == "Channel Integration") {
          let obj = {
            criterionId: this.$route.params.id,
            juristicId: juristicId,
            companyId: companyId,
            type: "credit",
          };
          res = await this.$store.dispatch(
            "user/fetchChannelInterestProductDetails",
            obj
          );
        }

        // check response
        if (typeof res == "object") {
          // success
          if (res.success) {
            let obj = [];
            res.obj.forEach((e) => {
              obj.push({
                ...e,
                statusShowDetail: false,
              });
            });
            this.fetchStatusCredit = obj;
            this.loadingStatusCredit = false;
          } else {
            if (res.status == 401) {
              this.checkAuth();
            } else if (res.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(res.data);
            }
            return;
          }
        }

        // HTTP.post(`user/details/credit`, obj) // /user
        //   .then((res) => {
        //     if (res.data.success) {
        //       let obj = [];
        //       res.data.obj.forEach((e) => {
        //         obj.push({
        //           ...e,
        //           statusShowDetail: false,
        //         });
        //       });
        //       this.fetchStatusCredit = obj;
        //       this.loadingStatusCredit = false;
        //     }
        //   })
        //   .catch((e) => {
        //     if (e.response.status != 502) {
        //       if (e.response.data.status == 401) {
        //         this.checkAuth();
        //       } else if (e.response.data.status == 502) {
        //         console.log("Bad GetWay");
        //       } else {
        //         this.alertCatchError(e);
        //       }
        //     }
        //   });
      } catch (e) {
        console.log("err: ", e);
        this.alertCatchError(e);
        return;
      }
    },
    async getInsuranceById(juristicId, companyId, branch) {
      try {
        this.loadingStatusInsurance = true;
        this.dialogStatusInsurance = true;

        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        var res = null;

        if (branch == "TERAK") {
          let obj = {
            criterionId: this.$route.params.id,
            juristicId: juristicId,
            companyId: companyId,
          };

          res = await this.$store.dispatch(
            "user/fetchInterestInsuranceDetails",
            obj
          );
        } else if (branch == "Channel Integration") {
          let obj = {
            criterionId: this.$route.params.id,
            juristicId: juristicId,
            companyId: companyId,
            type: "insurance",
          };
          res = await this.$store.dispatch(
            "user/fetchChannelInterestProductDetails",
            obj
          );
        }

        if (typeof res == "object") {
          if (res.success) {
            this.fetchStatusInsurance = res.obj;
            this.loadingStatusInsurance = false;
            return;
          } else {
            if (res.status == 401) {
              this.checkAuth();
            } else if (res.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(res.data);
            }
            return;
          }
        }
      } catch (e) {
        console.log("err: ", e);
        this.alertCatchError(e);
        return;
      }

      // HTTP.post(`user/details/insurance`, obj) // /user
      //   .then((res) => {
      //     if (res.data.success) {
      //       this.fetchStatusInsurance = res.data.obj;
      //       this.loadingStatusInsurance = false;
      //     }
      //   })
      //   .catch((e) => {
      //     if (e.response.status != 502) {
      //       if (e.response.data.status == 401) {
      //         this.checkAuth();
      //       } else if (e.response.data.status == 502) {
      //         console.log("Bad GetWay");
      //       } else {
      //         this.alertCatchError(e);
      //       }
      //     }
      //   });
    },
    exportAll() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/export/${this.$route.params.id}`)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
              HTTP.delete(`file/${res.data.obj.filename}`);
            }, 1000);
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingExport = false;
        });
    },
    exportETC() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/export/etc/${this.$route.params.id}`)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
              HTTP.delete(`file/${res.data.obj.filename}`);
            }, 1000);
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingExport = false;
        });
    },
    openDialogManageExport() {
      this.dialogManageExport = true;
    },
    disbledIfOffer(value) {
      let result = false;
      if (this.form.offer.length > 0) {
        let find = this.form.offer.find((row) => row == "notOffer");
        if (typeof find != "undefined") {
          if (value != "notOffer") {
            result = true;
          }
        } else {
          if (value == "notOffer") {
            result = true;
          }
        }
      }
      return result;
    },
    disbledIfInterest(value) {
      let result = false;
      if (this.form.interest.length > 0) {
        let find = this.form.interest.find((row) => row == "notInterest");
        if (typeof find != "undefined") {
          if (value != "notInterest") {
            result = true;
          }
        } else {
          if (value == "notInterest") {
            result = true;
          }
        }
      }
      if (this.form.offer.length > 0) {
        let find = this.form.offer.find((row) => row == "notOffer");
        if (typeof find != "undefined") {
          if (value != "notInterest") {
            result = true;
          }
        }
      }
      return result;
    },
  },
};
</script>
