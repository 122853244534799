<template>
  <div>
    <div class="mg-y-5 pd-5 pd-b-6 bordered rounded">
      <el-form
        label-position="top"
        :rules="rules"
        ref="ruleForm"
        :model="userInfo"
      >
        <el-row :gutter="10">
          <el-col :span="12" :md="user.role.roleLevel != 60 ? 6 : 8">
            <el-form-item
              style="text-align: initial"
              prop="date"
              class="padding-less custom-form"
            >
              <template slot="label" class="is-flex">
                <span class="select is-required font-24"> วันที่นำเสนอ </span>
                <el-checkbox
                  v-model="userInfo.allTime"
                  @change="userInfo.date = null"
                  >ทั้งหมด</el-checkbox
                >
              </template>
              <el-date-picker
                v-model="userInfo.date"
                type="daterange"
                range-separator="ถึง"
                start-placeholder="วันที่เริ่มต้น"
                end-placeholder="วันที่สิ้นสุด"
                class="w-100"
                :disabled="userInfo.allTime == true ? true : false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" :md="6" v-if="user.role.roleLevel != 60">
            <el-form-item label="สถานะเกรด" class="padding-less">
              <el-select
                multiple
                filterable
                clearable
                default-first-option
                v-model="userInfo.grade"
                placeholder="เลือกสถานะเกรด"
                class="w-100"
                popper-class="custom-select"
                @change="selectGrade"
              >
                <el-option
                  v-for="item in gradeAll"
                  :key="item._id"
                  :label="item.gradeName"
                  :value="item._id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.gradeName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :md="user.role.roleLevel != 60 ? 6 : 8">
            <el-form-item label="สถานะ Lead" class="padding-less">
              <el-select
                multiple
                filterable
                clearable
                default-first-option
                v-model="userInfo.statusLead"
                placeholder="เลือกสถานะ Lead"
                class="w-100"
                name="statusLead"
                @change="selectLead"
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsLead"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <!-- :disabled="checkDisabledAll(item.value)" -->

                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.label }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :md="user.role.roleLevel != 60 ? 6 : 8">
            <el-form-item label="ที่มา" class="padding-less" prop="dataType">
              <el-select
                multiple
                filterable
                clearable
                default-first-option
                v-model="userInfo.dataType"
                placeholder="เลือกที่มา"
                class="w-100"
                name="dataType"
                @change="selectDataType"
                popper-class="custom-select"
              >
                <el-option
                  v-for="item in optionsDataType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.label }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="group">
      <el-col :span="24" class="mg-b-6">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personalAll"
            class="title"
            @change="checlAll('personal')"
          >
            <p>ข้อมูลส่วนตัว</p>
          </el-checkbox>
        </div>
      </el-col>
      <!-- <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.registerDate"
            @change="checkTikBox('personal')"
          >
            <p>สมัครเมื่อ</p>
          </el-checkbox>
        </div>
      </el-col> -->
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.title"
            @change="checkTikBox('personal')"
          >
            <p>คำนำหน้าชื่อ</p></el-checkbox
          >
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.name"
            @change="checkTikBox('personal')"
          >
            <p>ชื่อ</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.surname"
            @change="checkTikBox('personal')"
          >
            <p>นามสกุล</p>
          </el-checkbox>
        </div>
      </el-col>
      <!-- <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.gender"
            @change="checkTikBox('personal')"
          >
            <p>เพศ</p>
          </el-checkbox>
        </div>
      </el-col> -->
      <!-- <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.age"
            @change="checkTikBox('personal')"
          >
            <p>อายุ</p>
          </el-checkbox>
        </div>
      </el-col> -->
      <!-- <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.education"
            @change="checkTikBox('personal')"
          >
            <p>วุฒิการศึกษา</p>
          </el-checkbox>
        </div>
      </el-col> -->
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.email"
            @change="checkTikBox('personal')"
          >
            <p>อีเมล</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.personal.mobilephone"
            @change="checkTikBox('personal')"
          >
            <p>เบอร์โทรศัพท์มือถือ</p>
          </el-checkbox>
        </div>
      </el-col>
    </el-row>
    <el-row class="group">
      <el-col :span="24" class="mg-b-6">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.companyAll"
            class="title"
            @change="checlAll('company')"
          >
            <p>ข้อมูลบริษัท</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.companyName"
            @change="checkTikBox('company')"
          >
            <p>ชื่อบริษัท</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.juristicId"
            @change="checkTikBox('company')"
          >
            <p>เลขนิติบุคคล</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.establishmentYear"
            @change="checkTikBox('company')"
          >
            <p>ปีที่จัดตั้ง</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.registerCapital"
            @change="checkTikBox('company')"
          >
            <p>ทุนจดทะเบียน</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.businessKey"
            @change="checkTikBox('company')"
          >
            <p>รหัสธุรกิจ 5 หลัก (TSIC)</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.directorType"
            @change="checkTikBox('company')"
          >
            <p>ประเภทกรรมการ</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.companyMail"
            @change="checkTikBox('company')"
          >
            <p>อีเมลบริษัท</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.businessType"
            @change="checkTikBox('company')"
          >
            <p>ลักษณะธุรกิจ</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.cateIndustryType"
            @change="checkTikBox('company')"
          >
            <p>ประเภทของอุตสาหกรรม</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.industryType"
            @change="checkTikBox('company')"
          >
            <p>ประเภทย่อยของอุตสาหกรรม</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.memberOf"
            @change="checkTikBox('company')"
          >
            <p>หน่วยงานที่เป็นสมาชิก</p>
          </el-checkbox>
        </div>
      </el-col>

      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.latestAnnualRevenues"
            @change="checkTikBox('company')"
          >
            <p>รายได้ประจำปีล่าสุดของบริษัท (บาท)</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.operatorType"
            @change="checkTikBox('company')"
          >
            <p>ประเภทของผู้ประกอบการ</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.produceOrSoldExport"
            @change="checkTikBox('company')"
          >
            <p>มีการผลิต หรือ ขายให้กับผู้ส่งออก</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportPlan"
            @change="checkTikBox('company')"
          >
            <p>แผนที่จะส่งออก</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.expectedPercentageEtc"
            @change="checkTikBox('company')"
          >
            <p>ร้อยละที่คาดหวังว่าจะส่งออกได้/ ส่งออกได้เพิ่มขึ้น</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportExperience"
            @change="checkTikBox('company')"
          >
            <p>ประสบการณ์ในการส่งออก</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportRatio"
            @change="checkTikBox('company')"
          >
            <p>สัดส่วนในการส่งออก</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.orderConsistency"
            @change="checkTikBox('company')"
          >
            <p>ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportFrequency"
            @change="checkTikBox('company')"
          >
            <p>ความถี่ในการส่งออกโดยเฉลี่ย</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.shipment"
            @change="checkTikBox('company')"
          >
            <p>วงเงินค้าขายต่อ Shipment โดยเฉลี่ย</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.paymentTerm"
            @change="checkTikBox('company')"
          >
            <p>เทอมการชำระเงินโดยเฉลี่ย</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportValue"
            @change="checkTikBox('company')"
          >
            <p>มูลค่าการส่งออกโดยเฉลี่ยต่อปี</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.company.exportCountry"
            @change="checkTikBox('company')"
          >
            <p>จำนวนประเทศที่ทำการส่งออก</p>
          </el-checkbox>
        </div>
      </el-col>
    </el-row>
    <el-row class="group" v-if="user.role.roleLevel != 60">
      <el-col :span="24" class="mg-b-6">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.creditAll"
            class="title"
            @change="checlAll('credit')"
          >
            <p>ข้อมูลสินเชื่อที่สนใจ</p>
          </el-checkbox>
        </div>
      </el-col>

      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.credit.statusApprove"
            @change="checkTikBox('credit')"
          >
            <p>สถานะอนุมัติ</p>
          </el-checkbox>
        </div>
      </el-col>
      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.credit.reason"
            @change="checkTikBox('credit')"
          >
            <p>เหตุผล</p>
          </el-checkbox>
        </div>
      </el-col>
    </el-row>
    <el-row class="group" v-if="user.role.roleLevel != 70">
      <el-col :span="24" class="mg-b-6">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.insuranceAll"
            class="title"
            @change="checlAll('exportInsurance')"
          >
            <p>ข้อมูลประกันการส่งออกที่สนใจ</p>
          </el-checkbox>
        </div>
      </el-col>

      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.exportInsurance.pipelineStatus"
            @change="checkTikBox('exportInsurance')"
          >
            <p>สถานะ Pipeline</p>
          </el-checkbox>
        </div>
      </el-col>

      <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.exportInsurance.contactStatus"
            @change="checkTikBox('exportInsurance')"
          >
            <p>ผลการติดต่อลูกค้า</p>
          </el-checkbox>
        </div>
      </el-col>

      <!-- </el-row>
      </el-col> -->
      <!-- <el-col :span="24" :md="16">
        <el-row>
          <el-col :span="12">
            <div class="is-flex js-start ai-center">
              <el-checkbox
                v-model="userInfo.exportInsurance.approveInsurance"
                @change="checkTikBox('exportInsurance')"
              >
                <p>อนุมัติ</p>
              </el-checkbox>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="is-flex js-start ai-center">
              <el-checkbox
                v-model="userInfo.exportInsurance.waitApproveInsurance"
                @change="checkTikBox('exportInsurance')"
              >
                <p>รออนุมัติ</p>
              </el-checkbox>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24" :md="8">
        <el-col :span="24">
          <div class="is-flex js-start ai-center">
            <el-checkbox
              v-model="userInfo.exportInsurance.notApproveInsurance"
              @change="checkTikBox('exportInsurance')"
            >
              <p>ไม่อนุมัติ (พร้อมเหตุผล)</p>
            </el-checkbox>
          </div>
        </el-col>
      </el-col> -->
    </el-row>
    <!-- <el-row class="group">
      <el-col :span="24" class="mg-b-6">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.projectAll"
            class="title"
            @change="checlAll('project')"
          >
            <p>ข้อมูลโครงการ</p>
          </el-checkbox>
        </div>
      </el-col>
       
       <el-col :span="12" :md="8">
        <div class="is-flex js-start ai-center">
          <el-checkbox
            v-model="userInfo.project.interestProject"
            @change="checkTikBox('project')"
          >
            <p>สนใจโครงการ</p>
          </el-checkbox>
        </div>
      </el-col>

    </el-row> -->
    <div class="text-center mg-t-3">
      <el-button
        type="primary"
        class="font-24"
        @click="sentExport"
        :disabled="
          (userInfo.date == '' && userInfo.allTime == false) ||
          (userInfo.date == null && userInfo.allTime == false)
        "
        >Export CSV</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  props: ["userInfo", "gradeAll", "permissionCredit", "permissionInsurance"],
  mounted() {
    if (this.userInfo.grade.length == this.gradeAll.length - 1) {
      this.userInfo.grade.unshift(999);
    }
    if (this.userInfo.statusLead[0] == 999) {
      this.optionsLead.forEach((e) => {
        if (e.value != 999) {
          this.userInfo.statusLead.push(e.value);
        }
      });
    }
    if (this.userInfo.dataType[0] == 999) {
      this.optionsDataType.forEach((e) => {
        if (e.value != 999) {
          this.userInfo.dataType.push(e.value);
        }
      });
    }
    this.constSelect = JSON.parse(JSON.stringify(this.userInfo.grade));
    this.constSelect1 = JSON.parse(JSON.stringify(this.userInfo.statusLead));
    this.constSelect2 = JSON.parse(JSON.stringify(this.userInfo.dataType));
  },
  data() {
    var checkDated = (rule, value, callback) => {
      if (!this.userInfo.allTime) {
        if (this.userInfo.date == null) {
          return callback(new Error("กรุณาเลือกวันที่นำเสนอ"));
        } else {
          return callback();
        }
      }
    };
    var checkGrade = (rule, value, callback) => {
      if (this.userInfo.grade.length <= 0) {
        return callback(new Error("กรุณาเลือกสถานะเกรด"));
      } else {
        return callback();
      }
    };

    return {
      constSelect: [],
      constSelect1: [],
      constSelect2: [],
      rules: {
        date: [{ validator: checkDated, trigger: "change" }],
        dataType: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกที่มา",
            trigger: "change",
          },
        ],
        statusLead: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกสถานะ Lead",
            trigger: "change",
          },
        ],
        gradeTest: [{ validator: checkGrade, trigger: "change" }],
      },
      optionsCriterion: [
        {
          value: 1,
          label: "ใช้งาน",
        },
        {
          value: 0,
          label: "ละเว้น",
        },
      ],
      optionsLead: [
        { value: 999, label: "ทั้งหมด" },
        { value: 1, label: "New" },
        { value: 2, label: "Pending" },
        { value: 3, label: "Active" },
        { value: 4, label: "Submitted" },
        { value: 0, label: "Close" },
      ],
      optionsDataType: [
        { value: 999, label: "ทั้งหมด" },
        {
          value: "TERAK",
          label: "TERAK",
        },
        {
          value: "Channel Integration",
          label: "Channel Integration",
        },
      ],
      // this.$store.state.userOption.statusCRM,
    };
  },
  methods: {
    sentExport() {
      if (this.user.role.roleLevel == 70) {
        this.userInfo.insuranceAll = false;
        this.userInfo.exportInsurance.pipelineStatus = false;
        this.userInfo.exportInsurance.contactStatus = false;
      }
      if (this.user.role.roleLevel == 60) {
        this.userInfo.grade = [];
        this.userInfo.creditAll = false;
        this.userInfo.credit.statusApprove = false;
        this.userInfo.credit.reason = false;
      }
      this.$emit("downloadCSV", this.userInfo);
    },
    checlAll(nameTab) {
      if (nameTab == "personal") {
        if (this.userInfo.personalAll == true) {
          // this.userInfo.personal.registerDate = true;
          this.userInfo.personal.title = true;
          this.userInfo.personal.name = true;
          this.userInfo.personal.surname = true;
          // this.userInfo.personal.gender = true;
          // this.userInfo.personal.age = true;
          // this.userInfo.personal.education = true;
          this.userInfo.personal.email = true;
          this.userInfo.personal.mobilephone = true;
        } else {
          // this.userInfo.personal.registerDate = false;
          this.userInfo.personal.title = false;
          this.userInfo.personal.name = false;
          this.userInfo.personal.surname = false;
          // this.userInfo.personal.gender = false;
          // this.userInfo.personal.age = false;
          // this.userInfo.personal.education = false;
          this.userInfo.personal.email = false;
          this.userInfo.personal.mobilephone = false;
        }
      } else if (nameTab == "company") {
        if (this.userInfo.companyAll == true) {
          this.userInfo.company.companyName = true;
          this.userInfo.company.juristicId = true;
          this.userInfo.company.establishmentYear = true;
          this.userInfo.company.registerCapital = true;
          this.userInfo.company.businessKey = true;
          this.userInfo.company.directorType = true;
          this.userInfo.company.companyMail = true;
          this.userInfo.company.produceOrSoldExport = true;
          this.userInfo.company.exportPlan = true;
          this.userInfo.company.businessType = true;
          this.userInfo.company.industryType = true;
          this.userInfo.company.cateIndustryType = true;
          this.userInfo.company.memberOf = true;
          // this.userInfo.company.employment = true;
          this.userInfo.company.latestAnnualRevenues = true;
          this.userInfo.company.operatorType = true;
          this.userInfo.company.exportExperience = true;
          this.userInfo.company.exportRatio = true;
          this.userInfo.company.orderConsistency = true;
          this.userInfo.company.exportFrequency = true;
          this.userInfo.company.shipment = true;
          this.userInfo.company.paymentTerm = true;
          this.userInfo.company.exportValue = true;
          this.userInfo.company.exportCountry = true;
          this.userInfo.company.expectedPercentageEtc = true;
        } else {
          this.userInfo.company.companyName = false;
          this.userInfo.company.juristicId = false;
          this.userInfo.company.establishmentYear = false;
          this.userInfo.company.registerCapital = false;
          this.userInfo.company.businessKey = false;
          this.userInfo.company.directorType = false;
          this.userInfo.company.companyMail = false;
          this.userInfo.company.produceOrSoldExport = false;
          this.userInfo.company.exportPlan = false;
          this.userInfo.company.businessType = false;
          this.userInfo.company.industryType = false;
          this.userInfo.company.cateIndustryType = false;
          this.userInfo.company.memberOf = false;
          // this.userInfo.company.employment = false;
          this.userInfo.company.latestAnnualRevenues = false;
          this.userInfo.company.operatorType = false;
          this.userInfo.company.exportExperience = false;
          this.userInfo.company.exportRatio = false;
          this.userInfo.company.orderConsistency = false;
          this.userInfo.company.exportFrequency = false;
          this.userInfo.company.shipment = false;
          this.userInfo.company.paymentTerm = false;
          this.userInfo.company.exportValue = false;
          this.userInfo.company.exportCountry = false;
          this.userInfo.company.expectedPercentageEtc = false;
        }
      } else if (nameTab == "credit") {
        if (this.userInfo.creditAll == true) {
          this.userInfo.credit.statusApprove = true;
          this.userInfo.credit.reason = true;
        } else {
          this.userInfo.credit.statusApprove = false;
          this.userInfo.credit.reason = false;
        }
      } else if (nameTab == "exportInsurance") {
        if (this.userInfo.insuranceAll == true) {
          this.userInfo.exportInsurance.pipelineStatus = true;
          this.userInfo.exportInsurance.contactStatus = true;
        } else {
          this.userInfo.exportInsurance.pipelineStatus = false;
          this.userInfo.exportInsurance.contactStatus = false;
        }
      }
      // else if (nameTab == "project") {
      //   if (this.userInfo.projectAll == true) {
      //     this.userInfo.project.interestProject = true;
      //     // this.userInfo.project.notInterestProject = true;
      //     // this.userInfo.project.projectInfo = true;
      //   } else {
      //     this.userInfo.project.interestProject = false;

      //     // this.userInfo.project.notInterestProject = false;
      //     // this.userInfo.project.projectInfo = false;
      //   }
      // }
    },
    // checkDisabledAll(id) {
    //   if (id != 999) {
    //     if (this.userInfo.statusLead.some((e) => e == 999) == true) {
    //       return true;
    //     }
    //   } else {
    //     if (this.userInfo.statusLead.some((e) => e != 999) == true) {
    //       return true;
    //     }
    //   }
    // },
    selectLead(val) {
      if (val[val.length - 1] == 999) {
        this.userInfo.statusLead = [];
        this.optionsLead.forEach((gAll) => {
          this.userInfo.statusLead.push(gAll.value);
        });
        this.constSelect1 = JSON.parse(
          JSON.stringify(this.userInfo.statusLead)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsLead.length) {
          this.userInfo.statusLead.splice(0, 1);
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.userInfo.statusLead)
          );
        } else if (this.constSelect1[0] === 999 && val[0] !== 999) {
          this.userInfo.statusLead = [];
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.userInfo.statusLead)
          );
        } else {
          let fn = this.constSelect1.findIndex((a) => a === 999);
          if (val.length == this.optionsLead.length - 1 && fn === -1) {
            this.userInfo.statusLead = [];
            this.optionsLead.forEach((gAll) => {
              this.userInfo.statusLead.push(gAll.value);
            });
            this.constSelect1 = JSON.parse(
              JSON.stringify(this.userInfo.statusLead)
            );
          }
        }
      }
    },
   selectDataType(val) {
      if (val[val.length - 1] == 999) {
        this.userInfo.dataType = [];
        this.optionsDataType.forEach((gAll) => {
          this.userInfo.dataType.push(gAll.value);
        });
        this.constSelect2 = JSON.parse(
          JSON.stringify(this.userInfo.dataType)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsDataType.length) {
          this.userInfo.dataType.splice(0, 1);
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.userInfo.dataType)
          );
        } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
          this.userInfo.dataType = [];
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.userInfo.dataType)
          );
        } else {
          let fn = this.constSelect2.findIndex((a) => a === 999);
          if (val.length == this.optionsDataType.length - 1 && fn === -1) {
            this.userInfo.dataType = [];
            this.optionsDataType.forEach((gAll) => {
              this.userInfo.dataType.push(gAll.value);
            });
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.userInfo.dataType)
            );
          }
        }
      }
    },
    selectGrade(val) {
      if (val[val.length - 1] == 999) {
        this.userInfo.grade = [];
        this.gradeAll.forEach((gAll) => {
          this.userInfo.grade.push(gAll._id);
        });
        this.constSelect = JSON.parse(JSON.stringify(this.userInfo.grade));
      } else {
        if (val[0] === 999 && val.length < this.gradeAll.length) {
          this.userInfo.grade.splice(0, 1);
          this.constSelect = JSON.parse(JSON.stringify(this.userInfo.grade));
        } else if (this.constSelect[0] === 999 && val[0] !== 999) {
          this.userInfo.grade = [];
          this.constSelect = JSON.parse(JSON.stringify(this.userInfo.grade));
        } else {
          let fn = this.constSelect.findIndex((a) => a === 999);
          if (val.length == this.gradeAll.length - 1 && fn === -1) {
            this.userInfo.grade = [];
            this.gradeAll.forEach((gAll) => {
              this.userInfo.grade.push(gAll._id);
            });
            this.constSelect = JSON.parse(JSON.stringify(this.userInfo.grade));
          }
        }
      }
    },
    checkTikBox(nameTab) {
      let result = {};
      if (nameTab == "personal") {
        result = Object.values(this.userInfo.personal);
      } else if (nameTab == "company") {
        result = Object.values(this.userInfo.company);
      } else if (nameTab == "credit") {
        result = Object.values(this.userInfo.credit);
      } else if (nameTab == "exportInsurance") {
        result = Object.values(this.userInfo.exportInsurance);
      } else if (nameTab == "project") {
        result = Object.values(this.userInfo.project);
      }

      if (result.filter((e) => e == true).length == result.length) {
        if (nameTab == "personal") {
          this.userInfo.personalAll = true;
        } else if (nameTab == "company") {
          this.userInfo.companyAll = true;
        } else if (nameTab == "credit") {
          this.userInfo.creditAll = true;
        } else if (nameTab == "exportInsurance") {
          this.userInfo.insuranceAll = true;
        } else if (nameTab == "project") {
          this.userInfo.projectAll = true;
        }
      } else {
        if (nameTab == "personal") {
          this.userInfo.personalAll = false;
        } else if (nameTab == "company") {
          this.userInfo.companyAll = false;
        } else if (nameTab == "credit") {
          this.userInfo.creditAll = false;
        } else if (nameTab == "exportInsurance") {
          this.userInfo.insuranceAll = false;
        } else if (nameTab == "project") {
          this.userInfo.projectAll = false;
        }
      }
    },
  },
};
</script>
